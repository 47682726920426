
.fixed-header {
  width: 100%;
  position: fixed;

  padding: 10px 0;
  color: #fff;
}
.fixed-header {
  top: 0;
}
.span{
  text-decoration: underline
}

@media print {
  body * {
    visibility: hidden;
  }
  .section-to-print,
  .section-to-print * {
    visibility: visible;
  }
  .section-to-print {
    position: absolute;
    left: 0;
    top: 0;
  }
}