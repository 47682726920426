.checkbox-select {
    // position: relative;
    // max-width: 330px;
    width: 100%;
    height: auto;
    border-radius: 1rem;

    @media only screen and (max-width: 600px) {
        margin: 100px auto 0;
    }

    &__trigger {
        justify-content: space-between;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        background: #fff;
        position: relative;
        z-index: 100;
        height: calc(1.5em + 0.75rem + 2px);
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        // box-shadow: 0 0 10px 8px rgba(0, 0, 0, 0.13);
        // height: 2.3rem;
        display: flex;
        align-items: center;
        cursor: pointer;
        // padding: 0 25px;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        @media only screen and (max-width: 600px) {
            padding: 0 15px;
            height: 70px;
        }

        &.isActive {
            border-radius: 10px 10px 0 0;
            background: #f2f2f2;

            svg {
                transform: rotate(-180deg);
            }
        }

        &:hover {
            background: #f4f4f4;
        }

        svg {
            width: 28px;
            stroke: 4px;
            transition: all 0.4s ease;

            @media only screen and (max-width: 600px) {
                width: 22px;
            }
        }
    }

    &__dropdown {
        opacity: 0;
        display: none;
        // visibility: hidden;
        background: #fff;
        // position: absolute;
        // left: 0;
        // right: 0;
        box-shadow: 0 12px 15px 6px rgba(0, 0, 0, 0.1);
        border-radius: 0 0 8px 8px;
        overflow: hidden;
        padding-bottom: 25px;

        &:after &:before {
            // position: absolute;
            content: "";
            top: 0;
            display: block;
            height: 4px;
            // z-index: 100;
        }

        &:after {
            opacity: 0;
            background: #000;
            left: -200px;
            width: 150px;
            background-color: #2980b9;
            transition: opacity 0.3s ease;
            animation: load 1.8s linear infinite;
            background: linear-gradient(135deg, rgba(143, 36, 237, 1) 20%, rgba(143, 36, 237, 1) 20%, rgba(143, 36, 237, 1) 22%, rgba(143, 36, 237, 1) 25%, rgba(16, 124, 179, 1) 100%);
        }

        &:before {
            width: 100%;
            background-color: #000;
        }

        &.activeSearch {
            &:after {
                opacity: 1;
            }
        }

        .simplebar-scrollbar {
            width: 3px;
            right: 1px;
        }
    }

    &__search-wrapp {
        padding: 10px 25px 5px;

        @media only screen and (max-width: 600px) {
            padding: 10px 15px 5px;
        }

        input {
            width: 100%;
            height: 40px;
            border-width: 0 0 2px;
            border-style: solid;
            border-color: #000;
            font-size: 16px;
            font-family: "Roboto Slab", serif;
            background: transparent;
        }

        ::-webkit-input-placeholder {
            /* Chrome/Opera/Safari */
            color: #b8b8b8;
            opacity: 1;
        }

        ::-moz-placeholder {
            /* Firefox 19+ */
            color: #b8b8b8;
            opacity: 1;
        }

        :-ms-input-placeholder {
            /* IE 10+ */
            color: #b8b8b8;
            opacity: 1;
        }

        :-moz-placeholder {
            /* Firefox 18- */
            color: #b8b8b8;
            opacity: 1;
        }
    }

    &__col {
        display: flex;
        font-size: 12px;
        padding: 0 25px;
        justify-content: space-between;
        text-transform: uppercase;

        @media only screen and (max-width: 600px) {
            padding: 0 15px;
        }
    }

    &__select-all {
        label {
            cursor: pointer;
        }

        input {
            display: none;
        }
    }

    &__filters-wrapp {
        margin-top: 20px;
        height: 157px;
        overflow-y: auto;
    }

    &__check-wrapp {
        position: relative;
        padding: 0 25px;
        margin-bottom: 5px;

        @media only screen and (max-width: 600px) {
            padding: 0 15px;
        }

        input[type="checkbox"] {
            display: none;

            &+label {
                position: relative;
                cursor: pointer;
                font-size: 16px;
                line-height: 22px;
                padding-left: 30px;
                display: inline-block;
                -webkit-touch-callout: none;
                -webkit-user-select: none;
                -khtml-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                transition: padding 0.25s ease;

                &:after {
                    border: solid 2px #000;
                    content: "";
                    width: 22px;
                    height: 22px;
                    top: 0;
                    left: 0;
                    position: absolute;
                }

                &:before {
                    width: 14px;
                    height: 14px;
                    content: "";
                    position: absolute;
                    top: 4px;
                    left: 4px;
                    background-color: #000;
                    opacity: 0;
                    will-change: transform;
                    transform: scale(0.5);
                    transition: all 0.2s ease;
                }

                &:hover {
                    padding-left: 32px;
                }
            }

            &:checked {
                &+label {
                    &:before {
                        opacity: 1;
                        transform: scale(1);
                    }
                }
            }
        }
    }
}

@keyframes load {
    0% {
        left: -200px;
        width: 20%;
    }

    50% {
        width: 40%;
    }

    70% {
        width: 60%;
    }

    80% {
        left: 50%;
    }

    95% {
        left: 120%;
    }

    100% {
        left: 100%;
    }
}